<template>
	<span>
		<!-- // this should be doable via v-component :is rather than multiple v-ifs but 
			theres some sort of a loading order issue and I am getting the error
			Unknown custom element: <v-text-field> - did you register the component correctly?
			Think what is happening is that it is trying to render the component before vuetify is loading, but 
			I can't work out why it is happening specifically on this file
			and not e.g. on a similar section in /OrganisationForm.vue
		-->
		<v-text-field
		v-if="component == 'v-text-field'"
		:label="mwtr[label]"
		v-model="value"
		></v-text-field>

		<v-textarea
		v-if="component == 'v-textarea'"
		:label="mwtr[label]"
		v-model="value"
		></v-textarea>

		<v-select
		v-if="component == 'v-select'"
		@
		:label="mwtr[label]"
		v-model="value"
		v-bind="options"
		></v-select>
		
	</span>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	export default {
		name: "GeneralField", 
		props: {
			component: {},
			label: {},
			options: {},
			data: {}, 
		},
		data: () => {
			return {
				value: ""
			}
		},
		watch: {
			value(){
				this.$emit("update", this.value )
			},
			data: {
				immediate: true,
				handler(){
					if( this.value !== this.data ){
						this.value = this.data
					}
				}
			}
			// this.ready = true
		},

	}
// </script>"