<template>
	<v-sheet flat>	
		<v-row>		
			<v-col cols="12" sm="12">
				<GeneralForm :id="id" collection="tags" :data="tag">
					<template v-slot:formContent>
						<GeneralField 
						v-for="field in fields" 
						:data="tag[field.value]" 
						:key="field.value"
						@update="(value) => tag[field.value] = value" 
						v-bind="field"></GeneralField>
					</template>
				</GeneralForm>
			</v-col>

		</v-row>
	</v-sheet>
</template>

<style lang="less">
	
</style>

<script type="text/javascript">
	import Vuex from "vuex";
	import GeneralForm from "@/components/admin/forms/GeneralForm"
	import GeneralField from "@/components/admin/forms/fields/GeneralField"

	export default {
		name: "EditTag", 
		props: {
			id: {}
		},
		data: () => {
			return {
			}
		},
		computed: {
			...Vuex.mapState( {
				tags: state => state.tags
			}),
			fields(){
				return this.mwsettings.getFields("Tag");
			},
			tag(){
				var tag = this.$store.state.tags[this.id]
				return tag || this.mwsettings.getEmpty("Tag");
			}
		},
		components: {
			GeneralForm, 
			GeneralField
		},
		created(){
			if( this.id ){			
				this.$store.dispatch("tags/fetchOne", this.id)
			}
		},
		destroyed(){
			this.$store.dispatch("detachOne", this.id)
		},

	}
// </script>"